import React, { useState } from "react";
import { Transition } from 'react-transition-group';

import { MDXRenderer } from "gatsby-plugin-mdx";
import * as icons from "@heroicons/react/outline";

import AreaCard from './AreaCard'

const AreaList = ({ areas, areaItems }) => {
  const [selectedPage, setSelectedPage] = useState("none")

  let content;

  const titleize = s => s.slice(0, 1).toUpperCase() + s.slice(1, s.length).replace(/-./g, x => ` ${x[1].toUpperCase()}`)

  if (selectedPage != "none") {
    const Icon = icons["ArrowCircleLeftIcon"]
    console.log("selected page:", selectedPage)
    content = selectedPage && (
      <div class="flex flex-col max-w-2/3">
        <div class="flex flex-row justify-start py-2">
          <Icon class="h-7 w-7 group-hover:text-pink-200 transition ease-in-ease-out inline mt-1" />
          <button onClick={() => { setSelectedPage("none") }} class="text-lg font-semibold underline hover:bg-pink-100 transition-all ease-in-ease-out">
            back
          </button>
        </div>
        <h1 class="text-lg font-semibold">Area: {titleize(selectedPage.frontmatter.area)}</h1>
        <article class="prose lg:prose-xl">
          <h2>{selectedPage.frontmatter.title}</h2>
          <p class="italic">
            {selectedPage.frontmatter.description}
          </p>
          <MDXRenderer>{selectedPage.body}</MDXRenderer>
        </article>
      </div>
    )

  } else {
    content =
      areas && (
        <>
          <h1 className="text-3xl font-bold self-start mb-5">Research Areas</h1>
          <div className="grid gap-5 md:grid-cols-2 auto-rows-fr grid-flow-row">
            {areas.map((n) => (<AreaCard area={n} setSelectedPage={setSelectedPage} allAreaItems={areaItems} />))}
          </div>
        </>
      )
  }
  return (
    <div className="transition ease-in-ease-out flex flex-col w-full p-5 justify-center items-center">
      {content}
    </div>
  )
}


export default AreaList;
