import React, { useState } from "react";
import { Transition } from 'react-transition-group';

import * as icons from "@heroicons/react/outline";


const AreaPageItem = ({ pg, setSelectedPage }) => {
  console.log("pg frontmatter:", pg.frontmatter)
  const Icon = icons[pg.frontmatter.icon] || icons["ArrowCircleRightIcon"];

  const navToPageOrAsset = (pg) => {
    if (pg.frontmatter.hasContent) {
      setSelectedPage(pg)
    } else {
      // goto(pg.frontmatter.url)
      window.location.href = pg.frontmatter.url
    }
  }
  return (
    <button class="col-span-1 group p-1 group-hover hover:cursor-pointer"
      key={pg.id}
      onClick={(e) => navToPageOrAsset(pg)}>
      <div class="flex flex-col content-left">
        <div class="flex flex-row">
          <div class="flex flex-col justify-center h-full">
            <Icon class="h-7 w-7 group-hover:text-pink-200 transition ease-in-ease-out inline mt-1" />
          </div>
          <div class="flex flex-col p-1">
            <h1 class="
            transition ease-in-ease-out 
            font-bold 
            group-hover:underline 
            group-hover:cursor-pointer
            transform 
            text-left
            w-fit
            group-hover:bg-pink-100">
              {pg.frontmatter.title}
            </h1>
            <p class="text-sm text-left group-hover:underline decoration-pink-200">{pg.frontmatter.description}</p>
          </div>
        </div>
      </div>
    </button>
  )
}

const kebabCase = string => string
  .replace(/([a-z])([A-Z])/g, "$1-$2")
  .replace(/[\s_]+/g, '-')
  .toLowerCase();


// style={{ gridRow: `span-${Math.max(Math.ceil(matchingPages.length / 2), 1)}` }}>
export default ({ area, allAreaItems, setSelectedPage }) => {
  const matchingPages = allAreaItems.filter((n) => n.frontmatter.area == kebabCase(area.frontmatter.title))
  const pages = matchingPages.map((pg) => (<AreaPageItem pg={pg} setSelectedPage={setSelectedPage} />));
  return (
    <div key={area.id}
      class={`transition-shadow ease-in-ease-out 
md:hover:shadow-xl card md:border-4 
md:border-black h-fit min-w-fit 
span-${Math.max(Math.ceil(matchingPages.length / 2), 1)}`}>
      <div class="card-body p-0 md:border-b-none md:p-5">
        <h2 class="card-title">{area.frontmatter.title}</h2>
        <p>{area.frontmatter.summary}</p>
      </div>
      <div class={`flex flex-col w-full h-full md:p-5 justify-start`}>{pages}</div>
      <div class="divider md:hidden"></div>
    </div>
  );
}

